












































































































































import debounce from 'just-debounce-it'
import mixins from 'vue-typed-mixins'
import FilterDropdown from '../../components/shared/FilterDropdown.vue'
import {
  FulfillmentStatusEnum,
  PaymentStatusEnum,
  OrderStatusEnum,
} from '../../../../shared/types/types'
import { formatDate } from '@/lib/utils'
import axios from 'axios'
import { getCurrentUser } from '@/lib/user'
import ExportFulfillmentModal from '@/components/fulfillment/ExportFulfillmentModal.vue'
import UploadFulfillmentModal from '@/components/fulfillment/UploadFulfillmentModal.vue'
import QueryParamsMixin from '@/mixins/QueryParams.mixin'
import { getElasticSearchClient } from '@/services/elasticSearch'

interface Row {
  orderId: string
  createdAt: number
  customerName: string
  status: string
  paymentStatus: string
  fulfillmentStatus: string
  product: {
    title: string
    productId: string
  }
}

export default mixins(QueryParamsMixin).extend({
  name: 'Fulfillment',
  components: {
    FilterDropdown,
  },
  data() {
    return {
      title: 'Fulfillment',
      searchInput: '',
      searchResult: [] as Record<string, any>,
      sort: [
        {
          createdAt: { order: 'desc' },
        },
      ],
      productIds: [],
      createType: null,
      status: [OrderStatusEnum.Open],
      paymentStatus: [PaymentStatusEnum.Paid],
      fulfillmentStatus: [
        FulfillmentStatusEnum.Partial,
        FulfillmentStatusEnum.Unfulfilled,
      ],
      dateRange: [],
      selectedLineItems: [] as any[],
      isLoading: false,
      itemsPerPage: 25,
      search: '',
      currentPage: 1,
    }
  },
  computed: {
    query(): Record<string, any> | null {
      if (this.productIds.length <= 0) {
        return null
      }
      const query: Record<string, any> = {
        query: {
          bool: {
            filter: [
              {
                terms: {
                  'product.productId': this.productIds,
                },
              },
            ],
            must: [],
          },
        },
        track_total_hits: true,
        sort: this.sort,
        size: this.itemsPerPage,
        from: (this.currentPage - 1) * this.itemsPerPage,
      }
      if (this.search && this.search.length > 0) {
        if (this.search.match(/^[0-9]+$/)) {
          // when search input is a number, search for orderId
          query.query.bool.filter.push({
            term: { orderId: this.search },
          })
        } else if (
          this.search.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)
        ) {
          // when search input is an email, search for customer email
          query.query.bool.filter.push({
            match: {
              'customer.email': {
                query: this.search,
                fuzziness: 0,
              },
            },
          })
        } else {
          // when search input is a string, search for customer name and product title
          query.query.bool.must.push({
            match: {
              'customer.name': {
                query: this.search,
              },
            },
          })
          delete query.sort
        }
      }
      if (this.status.length > 0) {
        query.query.bool.filter.push({
          terms: { status: this.status },
        })
      }
      if (this.paymentStatus.length > 0) {
        query.query.bool.filter.push({
          terms: { paymentStatus: this.paymentStatus },
        })
      }
      if (this.fulfillmentStatus.length > 0) {
        query.query.bool.filter.push({
          terms: { fulfillmentStatus: this.fulfillmentStatus },
        })
      }

      if (
        query.query.bool.filter.length === 0 &&
        query.query.bool.must.length === 0
      ) {
        query.query = {
          match_all: {},
        }
      }

      return query
    },
    nbHits(): number {
      return this.searchResult && this.searchResult.hits
        ? this.searchResult.hits.total.value
        : 0
    },
    rows(): Row[] {
      if (!this.searchResult || this.searchResult.length === 0) {
        return []
      }
      return this.searchResult.hits.hits.map((hit: Record<string, any>) => {
        const fulfillment = hit._source
        const row: Row = {
          orderId: fulfillment.orderId,
          createdAt: new Date(fulfillment.createdAt).getTime(),
          customerName: [
            fulfillment.customer.firstName,
            fulfillment.customer.lastName,
          ].join(' '),
          status: fulfillment.status,
          paymentStatus: fulfillment.paymentStatus,
          fulfillmentStatus: fulfillment.fulfillmentStatus,
          product: fulfillment.product,
        }
        return row
      })
    },

    statusFilterOptions(): Record<string, string>[] {
      return [
        {
          label: 'Open',
          value: OrderStatusEnum.Open,
        },
        {
          label: 'Archived',
          value: OrderStatusEnum.Archived,
        },
        {
          label: 'Canceled',
          value: OrderStatusEnum.Canceled,
        },
      ]
    },
    paymentFilterOptions(): Record<string, string>[] {
      return [
        {
          label: 'Paid',
          value: PaymentStatusEnum.Paid,
        },
        {
          label: 'Partially Refunded',
          value: PaymentStatusEnum.PartiallyRefunded,
        },
        {
          label: 'Refunded',
          value: PaymentStatusEnum.Refunded,
        },
        {
          label: 'Unpaid',
          value: PaymentStatusEnum.Unpaid,
        },
      ]
    },
    fulfillmentFilterOptions(): Record<string, string>[] {
      return [
        {
          label: 'Fulfilled',
          value: FulfillmentStatusEnum.Fulfilled,
        },
        {
          label: 'Partially Fulfilled',
          value: FulfillmentStatusEnum.Partial,
        },
        {
          label: 'Unfulfilled',
          value: FulfillmentStatusEnum.Unfulfilled,
        },
      ]
    },
  },
  async created() {
    const { fulfillmentGroupId } = this.$route.params

    const currentUser = await getCurrentUser()
    const { data } = await axios.get(
      `${process.env.VUE_APP_FULFILLMENT_ENDPOINT}/groups/${fulfillmentGroupId}`,
      {
        headers: {
          Authorization: currentUser.signInUserSession.idToken.jwtToken,
        },
      }
    )

    this.title = data.title
    this.productIds = data.productIds
    this.createType = data.createType
  },
  watch: {
    searchInput: {
      immediate: true,
      handler: debounce(function () {
        //@ts-ignore
        this.search = this.searchInput.trim()
      }, 500),
    },
    query: {
      immediate: true,
      deep: true,
      handler() {
        this.fetchData()
      },
    },
  },
  methods: {
    formatDate,
    async fetchData(): Promise<void> {
      if (this.query) {
        const elasticSearch = getElasticSearchClient()
        const { data } = await elasticSearch.post(
          '/fulfillments/_search',
          this.query
        )
        this.searchResult = data
      }
    },
    setSelectedLineItems(checkedLineItems: any[]) {
      this.selectedLineItems = checkedLineItems
    },
    // TODO: types
    isRowSelected(a: any, b: any) {
      return a.orderId === b.orderId
    },
    updateFilter(filterName: string, filterValue: any) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this[filterName] = filterValue
    },
    resetFilters() {
      this.status = []
      this.paymentStatus = []
      this.fulfillmentStatus = []
      this.dateRange = []
    },
    setLoading(value: boolean) {
      this.isLoading = value
    },
    cellClasses(order: Record<string, any>) {
      if (order.status === OrderStatusEnum.Canceled) {
        return {
          'line-through': true,
        }
      }
    },
    getStatusLabelFromValue(
      value: string,
      filterOptions: Record<string, string>[]
    ) {
      return (filterOptions.find((option) => option.value === value) || {})
        .label
    },
    getTagTypeForFulfillmentStatus(value: string) {
      if (value === FulfillmentStatusEnum.Unfulfilled) {
        return 'is-warning'
      }

      if (value === FulfillmentStatusEnum.Partial) {
        return 'is-warning is-light'
      }

      if (value === FulfillmentStatusEnum.Fulfilled) {
        return 'is-light'
      }
    },
    openExportModal() {
      this.$buefy.modal.open({
        parent: this,
        component: ExportFulfillmentModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          productIds: this.productIds,
        },
      })
    },
    openUploadModal() {
      this.$buefy.modal.open({
        parent: this,
        component: UploadFulfillmentModal,
        hasModalCard: true,
        trapFocus: true,
      })
    },
  },
})
