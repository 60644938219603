






























import Vue from 'vue'
import ModalHeader from '@/components/shared/ModalHeader.vue'
import axios from 'axios'
import { getCurrentUser } from '@/lib/user'

export default Vue.extend({
  name: 'ExportFulfillmentModal',
  components: {
    ModalHeader,
  },
  data() {
    return {
      file: null,
      importId: '',
      isLoading: false,
    }
  },
  methods: {
    async handleUpload() {
      this.isLoading = true
      const currentUser = await getCurrentUser()

      const { data } = await axios.post(
        `${process.env.VUE_APP_FULFILLMENT_ENDPOINT}/import`,
        {},
        {
          headers: {
            Authorization: currentUser.signInUserSession.idToken.jwtToken,
          },
        }
      )

      this.importId = data.importId

      this.uploadCSV(data.url)
    },
    async uploadCSV(url: string) {
      await axios.put(url, this.file)

      this.$emit('close')

      this.$router.push(
        `/fulfillment/groups/${this.$route.params.fulfillmentGroupId}/import/${this.importId}`
      )
    },
  },
})
