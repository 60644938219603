




























import Vue from 'vue'

export default Vue.extend({
  name: 'FilterDropdown',
  props: {
    title: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    value: {
      validator: (value) => !!value,
      required: true,
    },
  },
  methods: {
    handleInput(value: boolean) {
      this.$emit('input', value)
    },
    handleResetAll() {
      this.$emit('input', [])
    },
  },
})
