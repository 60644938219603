





































import Vue from 'vue'
import ModalHeader from '@/components/shared/ModalHeader.vue'
import axios from 'axios'
import { getCurrentUser } from '@/lib/user'

export default Vue.extend({
  name: 'ExportFulfillmentModal',
  components: {
    ModalHeader,
  },
  props: {
    productIds: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      exportSelection: 'all',
      isLoading: false,
      csvUrl: '',
    }
  },
  methods: {
    async handleSave() {
      this.isLoading = true
      const currentUser = await getCurrentUser()

      const query = {
        query: {
          bool: {
            filter: [
              { terms: { 'product.productId': this.productIds } },
              { terms: { status: ['open'] } },
              { terms: { paymentStatus: ['paid'] } },
              { terms: { fulfillmentStatus: ['partial', 'unfulfilled'] } },
            ],
            must: [],
          },
        },
        track_total_hits: true,
      }

      const { data } = await axios.post(
        `${process.env.VUE_APP_FULFILLMENT_ENDPOINT}/export`,
        { elasticSearchQueryString: JSON.stringify(query) },
        {
          headers: {
            Authorization: currentUser.signInUserSession.idToken.jwtToken,
          },
        }
      )

      const exportId = data.exportId

      const interval = setInterval(async () => {
        const { data } = await axios.get(
          `${process.env.VUE_APP_FULFILLMENT_ENDPOINT}/export/${exportId}`,
          {
            headers: {
              Authorization: currentUser.signInUserSession.idToken.jwtToken,
            },
          }
        )

        if (data.status === 'DONE') {
          this.csvUrl = data.url
          this.isLoading = false

          clearInterval(interval)
        }
      }, 1000)
    },
  },
})
